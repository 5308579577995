<template>
	<div class="shopping-cart-summary">
		<h2>Din handlekurv</h2>

		<SelectOrShowTradeIn />

		<ShoppingCartCard
			v-for="section in shoppingCartSections"
			:key="section.type"
			:type="section.type"
			:card-items="section.value"
		/>

		<PaymentSummary showSubItems />
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import { ProductCategory } from "~/components/ecommerce/types/ProductCategory";
export default defineNuxtComponent({
	name: "ShoppingCartSummary",

	computed: {
		...mapGetters("shoppingCart", ["visibleCartItems"]),
		shoppingCartSections() {
			return [
				{ type: "mainItem", value: [{ ...this.mainItem, mainItem: true }] },
				{ type: "badgeItems", value: this.hardwareItems?.filter((item) => item.metadata?.badge) }, // E.g. "Ferdig påsatt" should be a separate section
				{ type: "hardwareItems", value: this.hardwareItems?.filter((item) => !item.metadata?.badge) },
				{ type: "subscriptionItems", value: this.subscriptionItems },
				{ type: "agreementItems", value: this.agreementItems },
			].filter(this.hasContent);
		},
		mainItem() {
			return this.visibleCartItems.filter(
				(x) =>
					x.type !== "old-swap-device" &&
					![ProductCategory.SOUND, ProductCategory.ACCESSORY, ProductCategory.ROUTER].includes(x.metadata?.category),
			)?.[0];
		},
		hardwareItems() {
			return (
				this.mainItem?.subItems?.filter((item) => item.type === "hardware") ||
				this.visibleCartItems?.filter((item) => item.type === "hardware") ||
				undefined
			);
		},
		subscriptionItems() {
			return this.mainItem?.subItems?.filter((item) => item.type === "subscription" || item.type === "msisdn");
		},
		agreementItems() {
			return this.mainItem?.subItems?.filter((item) => item.type === "agreement") || undefined;
		},
	},
	methods: {
		hasContent(section) {
			return section.type === "mainItem" ? section.value?.[0]?.metadata !== undefined : section.value?.length > 0;
		},
	},
});
</script>

<style lang="scss" scoped>
.shopping-cart-summary {
	padding: 0 1px;
	margin-bottom: -$spacing-m;
	color: $color-text;

	@include font-text-xs;

	@include breakpoint(mobile) {
		margin-top: -$spacing-l;
	}
}

h2 {
	@include font-title-s;
}
</style>
